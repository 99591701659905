import React, { useEffect, useState } from 'react'
import { checkUserUnauthorized, useAuth } from '../Auth'
import { AccountDetail } from './AccountDetailsList/Core/Model'
import {
  useGetAccountDetailsQuery,
  useGetBankDetailsQuery,
  useGetBillingDetailsQuery,
} from '../Services/AccountsDetails'
import SplashScreen from 'App/Loaders/SplashScreen'
import { toast } from 'react-toastify'
import { dateFormat, decrypt, defaultDateFormat } from 'config'
import { currencies } from 'Data/Currency'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link, useNavigate } from 'react-router-dom'
import { ProgressBar } from 'react-bootstrap'
import CardDetails from '../Home/PricingAndSubsrcription/Pricing/CardDeatils'
import {
  useCancelSubscriptionMutation,
  useGetBillingInvoiceQuery,
  useGetSubscriptionQuery,
} from '../Services/Payments'
import InvoiceDetails from '../Home/PricingAndSubsrcription/Pricing/Invoice'
import Loader from 'App/Loaders/BeatLoader'
import BillingDetails from '../Home/PricingAndSubsrcription/Pricing/BillingDetails'
import ConfirmationPopup from 'Template/helpers/components/ConfirmationPopup'

const Status = {
  1: 'Good',
  2: 'Bad',
}

const AccountDetailHeader: React.FC = () => {
  const navigate = useNavigate()
  const { currentUser, saveAuth, setCurrentUser } = useAuth()
  const personUser = currentUser?.userType === 3
  const customerUser = currentUser?.userType === 4
  const [account, setAccount] = useState<AccountDetail>()
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [cancelSubscriptionId, setCancelSubscriptionId] = useState<string | null>(null)
  const [isFormValid, setIsFormValid] = useState(false)
  const [isBillingValid, setIsBillingValid] = useState(false)
  const [cardData, setCardData] = useState({})
  const [billingData, setBillingData] = useState({})
  const [error, setError] = useState<any>()
  const {
    data: accountData,
    isSuccess,
    isError,
    error: accountError,
    isLoading,
  } = useGetAccountDetailsQuery()
  const { data: billingInvoice, isLoading: billingInvoiceLoading } = useGetBillingInvoiceQuery({})
  const { data: activePlanDetails, isLoading: activePlanDetailsLoading } = useGetSubscriptionQuery(
    {}
  )
  const [cancelSubscription, { isLoading: isLoadingCancel }] = useCancelSubscriptionMutation()

  const handleBillingChange = (data: any) => {
    setBillingData(data)
  }

  const handleCancelSubscription = async () => {
    if (cancelSubscriptionId) {
      try {
        const response = await cancelSubscription({ subscriptionId: cancelSubscriptionId }).unwrap()
        if (response.success) {
          toast.success(response.message)
          navigate('/pricing')
        }
      } catch (error: any) {
        toast.error('Error canceling subscription:', error)
      } finally {
        setShowConfirmation(false)
      }
    }
  }

  const handleShowConfirmation = (subscriptionId: string) => {
    setCancelSubscriptionId(subscriptionId)
    setShowConfirmation(true)
  }

  useEffect(() => {
    if (currentUser) {
      let accountDetails: React.SetStateAction<AccountDetail | undefined>
      const condition =
        isSuccess && accountData ? 'success' : isError && accountError ? 'error' : 'default'

      switch (condition) {
        case 'success':
          accountDetails = accountData!
          setAccount(accountDetails)
          setError('')
          break

        case 'error':
          setError(accountError!)
          break

        default:
          break
      }
    }
  }, [accountData, isSuccess, isError])

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message)
      checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
    }
  }, [error])

  const handleCardChange = (data: any) => {
    setCardData(data)
  }
  // Function to decrypt the active plan details
  const getDecryptedPlanDetails = (details) => {
    if (!details) return {}

    return {
      name: decrypt(details?.plan?.name),
      description: decrypt(details?.plan?.description),
      assetCount: decrypt(details?.plan?.assetCount),
      isImportExport: decrypt(details?.plan?.isImportExport) === 'true',
      isHistory: decrypt(details?.plan?.isHistory),
      isHistoryUnlimited: decrypt(details?.plan?.isHistoryUnlimited) === 'true',
      subscriptionStartDate: decrypt(details?.subscriptionStartDate),
      payMobSubId: decrypt(details?.payMobSubId),
    }
  }
  const decryptedDetails = getDecryptedPlanDetails(activePlanDetails?.activeSubscription)
  const currencyObject = currencies.find((currency) => currency.value === currentUser?.currency)
  const splitName = currencyObject?.name.split(' - ')
  const currencySymbol = splitName && splitName[2]
  const assetCount = account?.accountDetails?.assetCount || 0
  const maxAssetCount = decryptedDetails.assetCount
    ? decryptedDetails.assetCount
    : activePlanDetails?.activeSubscription.assetCount
  const percentage = maxAssetCount > 0 ? (assetCount / maxAssetCount) * 100 : 0

  return (
    <>
      <SplashScreen isLoadingTemplate={isLoading} />
      {!isLoading ? (
        <Row className='g-5 g-xl-8 mb-5'>
          <Col xl={2} lg={4} md={3} sm={4} xs={6}>
            <div className='card  h-100'>
              <div className='account_details_card d-flex px-3 py-5 justify-content-start'>
                <div className='account_details_icon me-2'>
                  <i className='la la-user fs-2' aria-hidden='true'></i>
                </div>
                <div className='account_details_desc'>
                  <div className='text-gray-900 col-form-label fw-bold fs-6 p-0 mb-2'>
                    Account No.
                  </div>
                  <div className='fw-semibold text-gray-400'>
                    {account?.accountDetails?.accountNo
                      ? account?.accountDetails?.accountNo
                      : 'N/A'}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xl={2} lg={4} md={3} sm={4} xs={6}>
            <div className='card  h-100'>
              <div className='account_details_card d-flex px-3 py-5 justify-content-start'>
                <div className='account_details_icon me-2'>
                  <i className='la la-user-clock fs-2' aria-hidden='true'></i>
                </div>
                <div className='account_details_desc'>
                  <div className='text-gray-900 col-form-label fw-bold fs-6 p-0 mb-2'>
                    Account Status:
                  </div>
                  <div className='fw-semibold text-gray-400'>
                    {Status[account?.accountDetails?.accountStatus] || 'N/A'}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          {!(personUser || customerUser) && (
            <Col xl={2} lg={4} md={3} sm={4} xs={6}>
              <div className='card  h-100'>
                <div className='account_details_card d-flex px-3 py-5 justify-content-start'>
                  <div className='account_details_icon me-2'>
                    <i className='la la-puzzle-piece fs-2' aria-hidden='true'></i>
                  </div>
                  <div className='account_details_desc'>
                    <div className='text-gray-900 col-form-label fw-bold fs-6 p-0 mb-2'>
                      No. of Assets:
                    </div>
                    <div className='fw-semibold text-gray-400'>
                      {account?.accountDetails?.assetCount
                        ? account?.accountDetails?.assetCount
                        : 0}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          )}
          <Col xl={2} lg={4} md={3} sm={4} xs={6}>
            <div className='card  h-100'>
              <div className='account_details_card d-flex px-3 py-5 justify-content-start'>
                <div className='account_details_icon me-2'>
                  <i className='la la-calendar fs-2' aria-hidden='true'></i>
                </div>
                <div className='account_details_desc'>
                  <div className='text-gray-900 col-form-label fw-bold fs-6 p-0 mb-2'>
                    Account Since:
                  </div>
                  <div className='fw-semibold text-gray-400'>
                    {account?.accountDetails?.createdAt
                      ? dateFormat(
                          account?.accountDetails?.createdAt,
                          currentUser?.dateFormat || defaultDateFormat
                        )
                      : 'N/A'}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      ) : null}
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Active Plan Details</h3>
          </div>
        </div>
        <div className='card-footer'>
          <div className='pb-5'>
            <h3 className='fw-bolder m-0'>Plan</h3>
          </div>
          <div className='d-flex mb-3'>
            <strong className='me-2'>Name:</strong>
            <span>{decryptedDetails.name || activePlanDetails?.activeSubscription?.name}</span>
          </div>
          <div className='d-flex mb-3'>
            <strong className='me-2'>Description:</strong>
            <p>
              {decryptedDetails.description || activePlanDetails?.activeSubscription?.description}
            </p>
          </div>
          {activePlanDetails?.activeSubscription.name !== 'Free Plan' && (
            <div className='d-flex align-items-center mb-3'>
              <strong className='me-2'>Subscription Date:</strong>
              <span>{dateFormat(decryptedDetails.subscriptionStartDate)}</span>
            </div>
          )}
          <div className='py-5'>
            <h3 className='fw-bolder m-0'>Usage</h3>
          </div>
          <div className='d-sm-flex align-items-center justify-content-between mb-3'>
            <strong className='me-2'>Total Assets:</strong>
            <div className='d-flex align-items-center'>
              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                  <div className='d-flex w-100 mt-auto mb-2'>
                    <span className='fw-bolder fs-6'>
                      {account?.accountDetails?.assetCount
                        ? account?.accountDetails?.assetCount
                        : 0}
                    </span>
                    <span>{'/'}</span>
                    <span className='fw-bolder fs-6'>
                      {decryptedDetails.assetCount ||
                        activePlanDetails?.activeSubscription?.assetCount}
                    </span>
                  </div>
                  <div className='h-5px mx-3 w-100 bg-light mb-3'>
                    <div
                      className='bg-success rounded h-5px'
                      style={{ width: `${percentage}%` }}
                      role='progressbar'
                      aria-valuenow={percentage}
                      aria-valuemin={0}
                      aria-valuemax={maxAssetCount > 0 ? maxAssetCount : 1}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex align-items-center justify-content-between mb-3'>
            <strong className='me-2'>Import / Export</strong>
            <div className='d-flex align-items-center'>
              <div className='header-checkbox me-3'>
                <input
                  type='checkbox'
                  className='form-check-input custom-form-check-input'
                  checked={
                    decryptedDetails?.isImportExport ||
                    activePlanDetails?.activeSubscription?.isImportExport
                  }
                />
              </div>
              <span>Supported</span>
            </div>
          </div>
          <div className='d-flex align-items-center justify-content-between mb-3'>
            <strong className='me-2'>History</strong>
            <div className='d-flex align-items-center'>
              <span>
                {decryptedDetails.isHistoryUnlimited
                  ? 'Unlimited'
                  : decryptedDetails.isHistory ||
                    activePlanDetails?.activeSubscription?.isHistory}{' '}
                days
              </span>
            </div>
          </div>
        </div>
      </div>
      {activePlanDetails?.activeSubscription?.name !== 'Free Plan' && (
        <div className='card card-custom card mb-5 mb-xl-10'>
          <div className='card-header'>
            <h3 className='card-title fw-bolder'>Cancel Subscription</h3>
            <div className='card-toolbar'></div>
          </div>
          <div className='card-body'>
            <p>
              This operation will cancel your active plan, and you'll be shifted to the default plan
              offered by Asset Rack. <strong>This operation is irrevocable.</strong>
            </p>
            <button
              type='button'
              className='btn btn-danger d-flex'
              onClick={() => handleShowConfirmation(decryptedDetails.payMobSubId)}
            >
              Unsubscribe Plan
            </button>
          </div>
        </div>
      )}
      <ConfirmationPopup
        deleteModal={showConfirmation}
        cancel={() => setShowConfirmation(false)}
        confirm={handleCancelSubscription}
        setupName='subscription'
      />
      <div className='card card-custom card mb-5 mb-xl-10'>
        <div className='card-header'>
          <h3 className='card-title fw-bolder'>Billing Details</h3>
        </div>
        <div className='card-body border-top p-lg-9 p-md-7 p-6'>
          <BillingDetails
            onBillingChange={handleBillingChange}
            setIsBillingValid={setIsBillingValid}
          />
        </div>
      </div>
      <div className='checkout-container p-0'>
        <div className='container px-0'>
          <Row>
            <Col xl='12'>
              <div className='card card-custom card mb-5 mb-xl-10'>
                <div className='card-header'>
                  <h3 className='card-title fw-bolder'>Card Details</h3>
                  <div className='card-toolbar'></div>
                </div>
                <div className='card-body border-top p-lg-9 p-md-7 p-6'>
                  <CardDetails onCardChange={handleCardChange} setIsFormValid={setIsFormValid} />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className='checkout-container p-0'>
        <div className='container px-0'>
          <Row>
            <Col xl='12'>
              <div className='card'>
                <div id='kt_account_profile_details' className='collapse show'>
                  <form noValidate className='form'>
                    <div>
                      <InvoiceDetails billingDetails={billingInvoice?.billingDetails} />
                    </div>
                  </form>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Loader loading={billingInvoiceLoading || isLoadingCancel} />
    </>
  )
}

export default AccountDetailHeader
