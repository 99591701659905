import { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { initialWarranty } from '../../Core/Model'
import {
  useAddWarrantyMutation,
  useGetWarrantyQuery,
  useUpdateWarrantyMutation,
} from 'App/Modules/Services/Warranty'
import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'
import { toast } from 'react-toastify'
import Loader from 'App/Loaders/BeatLoader'
import moment from 'moment'
import { decimalPlacesRegex, nonNumericKeys } from 'config'

const WarrantySchema = Yup.object().shape({
  length: Yup.number()
    .required('Month length is required')
    .max(999999999999999, 'Length should not exceed 15 digits'),
  expiryDate: Yup.date().required('Expiration date is required'),
  notes: Yup.string().trim().max(250, 'Note should not exceed 250 characters'),
})

const WarrantyModal = (props: any) => {
  const { currentUser, saveAuth, setCurrentUser } = useAuth()
  const [loading, setLoading] = useState(false)
  const warrantyId = props.warrantyId
  const {
    data: singleWarrantyData,
    error: singleWarrantyDataErr,
    isLoading,
    isSuccess,
  } = useGetWarrantyQuery({ id: warrantyId }, { skip: !warrantyId })

  const [addWarranty, { isLoading: isWarrantyAdd }] = useAddWarrantyMutation()

  const [updateWarranty, { isLoading: isUpdateWarranty }] = useUpdateWarrantyMutation()

  useEffect(() => {
    if (isWarrantyAdd || isUpdateWarranty || isLoading) setLoading(true)
    else setLoading(false)
  }, [isWarrantyAdd, isUpdateWarranty, isLoading])
  const formik = useFormik({
    initialValues: { ...initialWarranty },
    validationSchema: WarrantySchema,
    onSubmit: async (values, { resetForm }) => {
      values.assetId = Number(props.assetId)
      if (currentUser) {
        try {
          const data: any =
            warrantyId > 0 && singleWarrantyData?.warranty?.id
              ? await updateWarranty({ body: values, id: warrantyId }).unwrap()
              : await addWarranty(values).unwrap()
          if (data?.success) {
            toast.dismiss()
            toast.success(data?.message)
            resetForm()
            props.handleClose()
          } else {
            toast.dismiss()
            toast.error(data?.error?.data?.message)
            props.handleClose()
            checkUserUnauthorized(data?.error?.data, saveAuth, setCurrentUser, toast)
            resetForm()
          }
        } catch (error: any) {
          toast.error(error?.data?.message)
          props.handleClose()
          checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
        }
      }
    },
  })

  useEffect(() => {
    setLoading(false)
    switch (true) {
      case isSuccess && !!singleWarrantyData:
        formik.setValues({
          length: singleWarrantyData?.warranty?.length,
          expiryDate: moment(singleWarrantyData?.warranty?.expiryDate).format('YYYY-MM-DD'),
          notes: singleWarrantyData?.warranty?.notes,
        })
        break

      case !isSuccess && !!singleWarrantyDataErr:
        if (props && props.handleClose) {
          props.handleClose()
        }
        if (toast) {
          toast.error('Something went wrong')
        }
        break

      default:
        break
    }
  }, [singleWarrantyData, isSuccess])

  return (
    <>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <Modal
          show={props.show}
          onHide={() => {
            formik.resetForm()
            props.handleClose()
          }}
          backdrop='static'
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Asset Warranty</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form noValidate onSubmit={formik.handleSubmit} className='form'>
              <div className='mb-4'>
                <label className='fw-bold fs-6 mb-2' htmlFor='length'>
                  Length
                </label>
                <div className='fv-row'>
                  <div className='input-group'>
                    <input
                      type='number'
                      id='length'
                      className='form-control  form-control-solid'
                      aria-label='Amount (to the nearest dollar)'
                      value={formik.values.length}
                      onChange={(e) => {
                        const value = e.target.value
                        if (decimalPlacesRegex.test(value)) {
                          formik.setFieldValue('length', value)
                        }
                      }}
                      onKeyDown={(e) => {
                        nonNumericKeys(e)
                      }}
                      name='length'
                    />
                    <span className='input-group-text border-0'>Months</span>
                  </div>
                </div>
                <div>
                  {formik.errors.length && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.length}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='mb-4'>
                <label className='required fw-bold fs-6 mb-2' htmlFor='expiryDate'>
                  Expiration Date
                </label>
                <div className='fv-row'>
                  <div className='input-group'>
                    <input
                      type='date'
                      id='expiryDate'
                      className='form-control  form-control-solid'
                      aria-label='Amount (to the nearest dollar)'
                      onChange={formik.handleChange}
                      name='expiryDate'
                      value={formik?.values?.expiryDate}
                    />
                    <span className='input-group-text border-0'>
                      <i className='fa fa-calendar'></i>
                    </span>
                  </div>
                </div>
                <div>
                  {formik.touched.expiryDate && formik.errors.expiryDate && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.expiryDate}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='mb-4'>
                <label className='fw-bold fs-6 mb-2' htmlFor='Notes'>
                  Notes
                </label>
                <div>
                  <textarea
                    className='form-control'
                    data-val-length='Maximum length is 1000.'
                    data-val-length-max='1000'
                    cols={20}
                    rows={4}
                    data-val='true'
                    id='Notes'
                    name='notes'
                    onChange={formik.handleChange}
                    value={formik.values.notes}
                  ></textarea>
                </div>
                <div>
                  {formik.errors.notes && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.notes}</div>
                    </div>
                  )}
                </div>
              </div>
              <div
                className={`${warrantyId > 0 ? 'd-flex' : 'd-block'} justify-content-between align-items-center"`}
              >
                {warrantyId > 0 &&
                (props.ownerUser || (props.staffUser && !props.permission?.deleteWarranty)) ? (
                  <Button
                    className='btn btn-danger main-btn-style'
                    onClick={() => {
                      props.setConfirmDelete(true)
                      props.setDeletedId(warrantyId)
                      formik.resetForm()
                      props.handleClose()
                    }}
                  >
                    <span className='indicator-label'>Delete</span>
                  </Button>
                ) : null}
                <div className='text-end pt-2'>
                  <div
                    className='btn btn-light-primary   main-btn-style me-2'
                    onClick={() => {
                      formik.resetForm()
                      props.handleClose()
                    }}
                  >
                    Close{' '}
                  </div>
                  <Button
                    type='submit'
                    className='btn btn-primary main-btn-style'
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {!isWarrantyAdd || !isUpdateWarranty || !isLoading ? (
                      warrantyId > 0 ? (
                        <span className='indicator-label'>Update</span>
                      ) : (
                        <span className='indicator-label'>Add</span>
                      )
                    ) : (
                      <span className='indicator-progress d-block'>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </Button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}

export default WarrantyModal
