import { FC, Suspense, useEffect } from 'react'
import { Route, Routes, Navigate, useLocation } from 'react-router-dom'
import { MasterLayout } from '../../Template/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../Pages/Dashboard/DashboardWrapper'
import { useAuth } from 'App/Modules/Auth'
import { MenuTestPage } from '../Pages/MenuTestPage'
import { getCSSVariableValue } from '../../Template/assets/ts/_utils'
import { WithChildren } from '../../Template/helpers'
import Checkout from '../Pages/Checkout/Checkout'
import Reserve from 'App/Pages/Reserve/Reserve'
import Checkin from '../Pages/Checkin/Checkin'
import Lease from '../Modules/Lease/Lease'
import LeaseReturn from '../Modules/LeaseReturn/LeaseReturn'
import Move from '../Modules/Move/Move'
import Maintenance from '../Modules/Maintenance/Maintenance'
import Maintenances from 'App/Modules/Maintenance/Maintenances'
import Warranties from 'App/Modules/Warranties/Warranties'
import Dispose from '../Modules/Dispose/Dispose'
import Audit from 'App/Modules/Audit/Audit'
import Import from '../Modules/Import/Import'
import Export from 'App/Modules/Export/Export'
import Customers from '../Modules/Apps/CompanySetup/Customers/Customers'
import Persons from '../Modules/Apps/CompanySetup/Persons/Persons'
import Users from '../Modules/Apps/CompanySetup/Users/Users'
import SecurityGroups from '../Modules/Apps/CompanySetup/SecurityGroups/SecurityGroups'
import UserAssets from '../Modules/Apps/CompanySetup/Asset/Assets'
import AssetDetails from '../Modules/Apps/CompanySetup/Asset/Components/AssetDetail'
import AccountPage from '../Modules/Accounts/AccountPage'
import AccountDetailsPage from '../Modules/AccountDetails/AccountDetailsPage'
import SetupPage from '../Modules/Apps/CompanySetup/SetupPage'
import AddUsers from 'App/Modules/Apps/CompanySetup/Users/Components/AddUsers'
import CreateGroup from 'App/Modules/Apps/CompanySetup/SecurityGroups/Components/CreateGroup'
import EditUser from 'App/Modules/Apps/CompanySetup/Users/Components/EditUser'
import ReportByAssetTags from 'App/Modules/Apps/CompanySetup/Reports/AssetsReports/ReportByAssetTags/ReportByAssetTags'
import ReportByAssetPicture from 'App/Modules/Apps/CompanySetup/Reports/AssetsReports/ReportByAssetPicture/ReportByAssetPicture'
import ReportByCategories from 'App/Modules/Apps/CompanySetup/Reports/AssetsReports/ReportByCategories/ReportByCategories'
import ReportByDepartment from 'App/Modules/Apps/CompanySetup/Reports/AssetsReports/ReportByDepartment/ReportByDepartment'
import ReportByWarrantyInfo from 'App/Modules/Apps/CompanySetup/Reports/AssetsReports/ReportByWarrantyInfo/ReportByWarrantyInfo'
import ReportsBySites from 'App/Modules/Apps/CompanySetup/Reports/AssetsReports/ReportBySites/ReportBySites'
import ReportsByLocations from 'App/Modules/Apps/CompanySetup/Reports/AssetsReports/ReportByLocations/ReportByLocations'
import ReportsByPersonEmployee from 'App/Modules/Apps/CompanySetup/Reports/CheckoutReports/ReportsByPersonEmployee/ReportsByPersonEmployee'
import SetupAlerts from 'App/Modules/Alerts/SetupAlerts/SetupAlerts'
import AddEditAssets from 'App/Modules/Apps/CompanySetup/Asset/Components/AssetAddEdit'
import Subscription from 'App/Modules/Home/PricingAndSubsrcription/Subscription/Subscription'
import CheckoutPayment from 'App/Modules/Home/PricingAndSubsrcription/Pricing/CheckoutPayment'
import SuccessPayment from 'App/Modules/Home/PricingAndSubsrcription/SuccessPayment'
import FailurePayment from 'App/Modules/Home/PricingAndSubsrcription/FailurePayment'
import Invoice from 'App/Modules/AccountDetails/InvoiceDetails'

const PrivateRoutes = () => {
  const { currentUser } = useAuth()
  const path = localStorage.getItem('path')
  const permission = currentUser?.SecurityGroup
  const userType = currentUser?.userType ?? 0
  const setup = currentUser?.isSetupCompleted
  const importPermission =
    permission?.importAsset ||
    permission?.importSite ||
    permission?.importCategory ||
    permission?.importDepartment ||
    permission?.importCustomer ||
    permission?.importSite ||
    permission?.importMaintenance ||
    permission?.importPerson
  const exportPermission =
    permission?.exportAsset ||
    permission?.exportSite ||
    permission?.exportCategory ||
    permission?.exportDepartment ||
    permission?.exportCustomer ||
    permission?.exportMaintenance ||
    permission?.exportPerson
  const storedPlan = localStorage.getItem('selectedPlan')

  const initialRoute =
    userType === 1
      ? !setup
        ? '/apps/setup/wizard'
        : storedPlan
          ? '/payment'
          : '/dashboard'
      : userType === 2
        ? permission?.viewDashboard
          ? '/dashboard'
          : '/account/settings'
        : userType > 2
          ? '/assets'
          : '/'

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='' element={<Navigate to={initialRoute} />} />
        <Route path='auth/*' element={<Navigate to={initialRoute} />} />
        {((userType === 2 && permission?.viewAsset) ||
          userType === 1 ||
          userType === 3 ||
          userType === 4) && <Route path='assets' element={<UserAssets />} />}
        <Route path='asset/view/:id' element={<AssetDetails />} />
        {userType < 3 && (
          <>
            {((userType === 2 && permission?.viewDashboard) || userType === 1) && (
              <Route path='dashboard' element={<DashboardWrapper />} />
            )}
            {((userType === 2 && permission?.hasCheckout) || userType === 1) && (
              <Route path='checkout' element={<Checkout />} />
            )}
            {((userType === 2 && permission?.hasCheckin) || userType === 1) && (
              <Route path='checkin' element={<Checkin />} />
            )}
            {((userType === 2 && permission?.addAsset) || userType === 1) && (
              <Route path='asset/add' element={<AddEditAssets />} />
            )}
            {((userType === 2 && permission?.viewAsset) || userType === 1) && (
              <Route path='asset/view/:id' element={<AssetDetails />} />
            )}
            {((userType === 2 && permission?.updateAsset) || userType === 1) && (
              <Route path='asset/edit/:id' element={<AddEditAssets />} />
            )}
            {((userType === 2 && permission?.addAsset) || userType === 1) && (
              <Route path='asset/copy/:id' element={<AddEditAssets />} />
            )}
            {((userType === 2 && permission?.hasLease) || userType === 1) && (
              <Route path='lease' element={<Lease />} />
            )}
            {((userType === 2 && permission?.hasLeaseReturn) || userType === 1) && (
              <Route path='lease-return' element={<LeaseReturn />} />
            )}
            {((userType === 2 && permission?.hasMaintenance) || userType === 1) && (
              <Route path='maintenance' element={<Maintenance />} />
            )}
            {((userType === 2 && permission?.viewMaintenance) || userType === 1) && (
              <Route path='maintenances' element={<Maintenances />} />
            )}
            {((userType === 2 && permission?.hasMaintenance) || userType === 1) && (
              <Route path='alerts/maintenances/due' element={<Maintenance />} />
            )}
            {((userType === 2 && permission?.hasMaintenance) || userType === 1) && (
              <Route path='alerts/maintenances/overdue' element={<Maintenance />} />
            )}
            <Route path='alerts/v2' element={<SetupAlerts />} />
            {((userType === 2 && permission?.viewWarranties) || userType === 1) && (
              <Route path='warranties' element={<Warranties />} />
            )}
            {((userType === 2 && permission?.hasDispose) || userType === 1) && (
              <Route path='dispose' element={<Dispose />} />
            )}
            <Route path='warranties/expire' element={<Warranties />} />
            {((userType === 2 && permission?.hasMove) || userType === 1) && (
              <Route path='move' element={<Move />} />
            )}
            {((userType === 2 && permission?.viewAudit) || userType === 1) && (
              <Route path='audit' element={<Audit />} />
            )}
            {((userType === 2 && permission?.viewAsset) || userType === 1) && (
              <Route path='assets' element={<UserAssets />} />
            )}
            {((userType === 2 && permission?.viewAsset) || userType === 1) && (
              <Route path='list-of-assets' element={<UserAssets />} />
            )}
            {((userType === 2 && importPermission) || userType === 1) && (
              <Route path='import' element={<Import />} />
            )}
            {((userType === 2 && exportPermission) || userType === 1) && (
              <Route path='export' element={<Export />} />
            )}
            {((userType === 2 && permission?.viewPerson) || userType === 1) && (
              <Route path='persons' element={<Persons />} />
            )}
            {((userType === 2 && permission?.viewCustomer) || userType === 1) && (
              <Route path='customers' element={<Customers />} />
            )}
            {((userType === 2 && permission?.viewUser) || userType === 1) && (
              <Route path='users' element={<Users />} />
            )}
            {((userType === 2 && permission?.addUser) || userType === 1) && (
              <Route path='users/add' element={<AddUsers />} />
            )}
            {((userType === 2 && permission?.updateUser) || userType === 1) && (
              <Route path='users/edit/:id' element={<EditUser />} />
            )}
            {((userType === 2 && permission?.viewGroup) || userType === 1) && (
              <Route path='group-manager' element={<SecurityGroups />} />
            )}
            {((userType === 2 && permission?.addGroup) || userType === 1) && (
              <Route path='group-manager/create-group' element={<CreateGroup />} />
            )}
            {((userType === 2 && permission?.updateGroup) || userType === 1) && (
              <Route path='group-manager/edit-group/:id' element={<CreateGroup />} />
            )}
            {((userType === 2 && permission?.hasReserve) || userType === 1) && (
              <Route path='reserve' element={<Reserve />} />
            )}
            <Route path='menu-test' element={<MenuTestPage />} />
            {((userType === 2 && permission?.assetByTagId) || userType === 1) && (
              <Route path='apps/reports/asset-by-asset-tag' element={<ReportByAssetTags />} />
            )}
            {((userType === 2 && permission?.assetByTagIdPicture) || userType === 1) && (
              <Route
                path='apps/reports/assets-by-tag-with-pictures'
                element={<ReportByAssetPicture />}
              />
            )}
            {((userType === 2 && permission?.assetByCategory) || userType === 1) && (
              <Route path='apps/reports/asset-by-category' element={<ReportByCategories />} />
            )}
            {((userType === 2 && permission?.assetBySite) || userType === 1) && (
              <Route path='apps/reports/asset-by-site' element={<ReportsBySites />} />
            )}
            {((userType === 2 && permission?.assetByWarranty) || userType === 1) && (
              <Route
                path='apps/reports/asset-by-warranty-info'
                element={<ReportByWarrantyInfo />}
              />
            )}{' '}
            {((userType === 2 && permission?.assetByLocation) || userType === 1) && (
              <Route path='apps/reports/asset-by-location' element={<ReportsByLocations />} />
            )}{' '}
            {((userType === 2 && permission?.assetByDepartment) || userType === 1) && (
              <Route path='apps/reports/asset-by-department' element={<ReportByDepartment />} />
            )}{' '}
            {((userType === 2 && permission?.assetBySite) || userType === 1) && (
              <Route path='apps/reports/by-person-employee' element={<ReportsByPersonEmployee />} />
            )}
            {userType === 1 && <Route path='pricing' element={<Subscription />} />}
            {userType === 1 && <Route path='payment' element={<CheckoutPayment />} />}
            {userType === 1 && <Route path='payment/success' element={<SuccessPayment />} />}
            {userType === 1 && <Route path='payment/failure' element={<FailurePayment />} />}
          </>
        )}
        <Route
          path='account-details/*'
          element={
            <SuspensedView>
              <AccountDetailsPage />
            </SuspensedView>
          }
        />
        <Route
          path='account-details/invoice-details'
          element={
            <SuspensedView>
              <Invoice />
            </SuspensedView>
          }
        />
        <Route
          path='account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        {userType < 3 && (
          <Route
            path='apps/setup/*'
            element={
              <SuspensedView>
                <SetupPage permission={permission} userType={userType} />
              </SuspensedView>
            }
          />
        )}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
