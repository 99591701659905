import React, { useState, useEffect } from 'react'
import { useGetAllSitesQuery } from '../../Modules/Services/Sites'
import { useGetAllLocationsBySiteQuery } from '../../Modules/Services/Locations'
import { useGetAllDepartmentsQuery } from '../../Modules/Services/Departments'
import { useAddCheckinMutation } from '../../Modules/Services/Checkout'
import { useGetAllAssetsQuery } from 'App/Modules/Services/Assets'
import { CheckInInterface, defaultCheckInDetail as initialValues } from './Core/Model'
import { useAuth } from '../../Modules/Auth'
import { PageTitle } from '../../../Template/layout/core'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { checkUserUnauthorized } from '../../Modules/Auth'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getAssetStatusClass, multiSpace } from 'config'
import SplashScreen from 'App/Loaders/SplashScreen'
import CheckinModal from './Modal/CheckinModal'
import Loader from 'App/Loaders/BeatLoader'
import * as Yup from 'yup'
import AssetMinimalDetailModal from 'App/Pages/Checkout/Modal/AssetMinimalDetailModal'

const checkinSchema = Yup.object().shape({
  returnDate: Yup.date()
    .min(new Date(new Date().setHours(0, 0, 0, 0)), 'Return date cannot be a past date')
    .required('Return date is required'),
  checkinType: Yup.number().required('Required'),
  siteId: Yup.string()
    .nullable()
    .test('siteId-required', 'Site is required!', function (value) {
      const { checkinType } = this.parent
      if (checkinType === 2 && !value) {
        return !!value
      }
      return true
    }),
  notes: Yup.string().max(250, 'Note should not exceed 250 characters'),
})

const Checkin = () => {
  const { currentUser, saveAuth, setCurrentUser } = useAuth()
  const navigate = useNavigate()
  const [assets, setAssets] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [dataLoading, setDataLoading] = useState<boolean>(false)
  const [showCheckInModal, setShowCheckInModal] = useState(false)
  const [pendingAssets, setPendingAssets] = useState<any>([])
  const [selectedAssets, setSelectedAssets] = useState<any>([])
  const [selectAll, setSelectAll] = useState(false)
  const [personCheckout, setPersonCheckout] = useState(true)
  const [emailEnabled, setEmailEnabled] = useState(false)
  const [selected, setSelected] = useState<any>([])
  const [siteId, setSiteId] = useState<any>()
  const [skip, setSkip] = useState<boolean>(false)
  const [assignTo, setAssignTo] = useState<string>('')
  const [personEmail, setPersonEmail] = useState<string>('')
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(10)
  const [customErrors, setCustomErrors] = useState({ siteField: '', returnDate: '' })
  const [deleteDataArr, setDeleteDataArr] = useState<number[]>([])
  const [modalShow, setModalShow] = useState(false)
  const [assetId, setAssetId] = useState<any>()
  const { data: userSitesList } = useGetAllSitesQuery(currentUser?.id)
  const { data: userLocationList, error: locationError } = useGetAllLocationsBySiteQuery(
    { userId: currentUser?.id, id: siteId },
    { skip: !siteId }
  )
  const { data: userDepartmentList } = useGetAllDepartmentsQuery(currentUser?.id)
  const [addCheckin, { isLoading: isAdding }] = useAddCheckinMutation()
  const { data: assetsData, isLoading } = useGetAllAssetsQuery(
    {
      body: new URLSearchParams({ status: 'Checked out' }).toString(),
      page: page,
      limit: limit,
      keys: '',
    },
    { skip }
  )

  const formik = useFormik({
    initialValues: { ...initialValues },
    validationSchema: checkinSchema,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        switch (true) {
          case personCheckout === false && values?.siteId === undefined:
            setCustomErrors({ siteField: 'Site is required', returnDate: '' })
            setLoading(false)
            return undefined

          case !values?.returnDate:
            setCustomErrors({ returnDate: 'ReturnDate is required', siteField: '' })
            setLoading(false)
            return undefined

          default:
            setCustomErrors({ siteField: '', returnDate: '' })
            handleCheckinSubmit(values)
            break
        }
      } catch (error: any) {
        toast.error(error?.data?.message)
      }
    },
  })

  useEffect(() => {
    if (selectedAssets?.length > 1) {
      const assigneeType: any = []
      const personIds: any = []
      for (const asset of selectedAssets) {
        assigneeType.push(asset?.assigneeType)
      }
      const isSame = assigneeType?.every((val) => val === assigneeType[0])
      if (isSame && assigneeType[0] === 1) {
        for (const asset of selectedAssets) {
          personIds.push(asset?.assignedTo)
        }
        const isSame = personIds?.every((val) => val === personIds[0])
        if (isSame) {
          setPersonEmail(selectedAssets[0]?.assignedPerson?.email)
          formik.setFieldValue('personId', selectedAssets[0]?.assignedTo)
        } else {
          setPersonEmail('')
        }
      } else {
        setPersonEmail('')
      }
    } else if (selectedAssets?.length === 1) {
      if (selectedAssets[0]?.assigneeType === 1) {
        setPersonEmail(selectedAssets[0]?.assignedPerson?.email)
        formik.setFieldValue('personId', selectedAssets[0]?.assignedTo)
      } else {
        setPersonEmail('')
      }
    } else {
      setPersonEmail('')
    }
  }, [selectedAssets, personCheckout])

  useEffect(() => {
    setPersonCheckout(true)
  }, [personCheckout])

  useEffect(() => {
    if (assetsData) {
      setAssets(assetsData?.assets)
    }
  }, [assetsData])

  useEffect(() => {
    if (assets && pendingAssets && showCheckInModal == false) {
      handlePendingList()
    }
  }, [assets, showCheckInModal])

  const handlePendingList = () => {
    const data = assetsData?.assets?.filter((asset: any) => pendingAssets.includes(asset.id))
    setSelectedAssets(data)
    setDeleteDataArr([])
  }

  const handleCheckinSubmit = async (values: CheckInInterface) => {
    const data = {
      returnDate: values?.returnDate,
      checkinType: Number(values?.checkinType),
      notes: values?.notes ? values?.notes?.trim()?.replace(multiSpace, ' ') : '',
      personId: values?.personId,
      locationId: values?.locationId,
      siteId: values?.siteId,
      departmentId: values?.departmentId,
      assetId: pendingAssets,
      sendEmail: emailEnabled,
    }
    try {
      const result = await addCheckin(data).unwrap()
      if (result) {
        toast.dismiss()
        toast.success(result?.message ? result?.message : result?.data?.message)
        setSelectedAssets([])
        setPersonCheckout(false)
        setSiteId(undefined)
        setCustomErrors({ siteField: '', returnDate: '' })
        navigate('/checkin')
        formik.resetForm()
      }
    } catch (error: any) {
      toast.error(error?.data?.message)
      checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
    } finally {
      setLoading(false)
    }
  }

  const handleshowCheckInModal = () => {
    setShowCheckInModal(true)
  }

  const handleCloseCheckinModal = () => {
    setShowCheckInModal(false)
  }

  useEffect(() => {
    setDataLoading(false)
  }, [userLocationList])

  const handleSiteClick = ({ target: { value } }: any) => {
    if (value === '') {
      setCustomErrors({ siteField: 'Site is required', returnDate: '' })
      setSiteId(0)
    } else {
      setDataLoading(true)
      setSiteId(value)
      setSkip(false)
      setCustomErrors({ siteField: '', returnDate: '' })
      setDataLoading(false)
    }
  }

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = assets?.map((item: any) => item.id)
      setSelected(newSelecteds)
      setSelectAll(true)
      setDeleteDataArr(newSelecteds)
    } else {
      setSelected([])
      setSelectAll(false)
      setDeleteDataArr([])
    }
  }

  const handleCheckboxChange = (id: number) => {
    setSelected((prevSelected) => {
      const updatedSelected = prevSelected.includes(id)
        ? prevSelected.filter((itemId) => itemId !== id)
        : [...prevSelected, id]
      const isAllSelected = updatedSelected.length === assets?.length
      setSelectAll(isAllSelected)
      return updatedSelected
    })
    setDeleteDataArr((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((itemId) => itemId !== id)
      } else {
        return [...prevSelected, id]
      }
    })
  }

  useEffect(() => {
    if (isLoading) {
      setDataLoading(true)
    }
    setDataLoading(false)
  }, [isLoading])

  const deleteSelectedAssests = async () => {
    const data = selectedAssets?.filter((asset: any) => !deleteDataArr.includes(asset.id))
    setSelectedAssets(data)
    setDeleteDataArr([])
    setSelectAll(false)
    setSelected([])
  }

  const handleReturnDate = ({ target: { value } }: any) => {
    setDataLoading(true)
    setSkip(false)
    if (value) {
      setCustomErrors({ siteField: '', returnDate: '' })
    }
    setDataLoading(false)
  }

  const handleCancel = () => {
    setPersonCheckout(false)
    setSelectedAssets([])
    setPendingAssets([])
    setShowCheckInModal(false)
    setSiteId(undefined)
    setCustomErrors({ siteField: '', returnDate: '' })
    formik.resetForm()
  }

  useEffect(() => {
    if (formik.values.checkinType === 1) setPersonCheckout(true)
    else if (formik.values.checkinType === 2) setPersonCheckout(false)
  }, [formik.values.checkinType])

  const handleCheckinTypeChange = () => {
    setSiteId(undefined)
    setEmailEnabled(false)
    formik.setFieldValue('notes', '')
    formik.setFieldValue('siteId', '')
    formik.setFieldValue('locationId', '')
    formik.setFieldValue('departmentId', '')
  }

  const clickDeatilAsset = (id) => {
    if (assetId === id) {
      return setModalShow(true)
    }
    setAssetId(id)
    setLoading(true)
  }

  useEffect(() => {
    if (assetId) {
      setLoading(false)
      setModalShow(true)
    }
  }, [assetId])

  return (
    <>
      <SplashScreen isLoadingTemplate={isLoading} />
      <PageTitle breadcrumbs={[]}>Checkin</PageTitle>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>
              <span className='me-2'>
                <i className='la la-user-times fs-1'></i>
              </span>{' '}
              Check-In
            </h3>
          </div>
        </div>
        <div id='kt_account_profile_details' className='collapse show'>
          <div className='form'>
            <div className='card-body border-top p-lg-9 p-md-7 p-6'>
              <div>
                {' '}
                <p className='fw-bolder m-0 mb-3'>
                  Track the journey of each asset as it moves through your organization.
                </p>
                <div className='card-footer d-flex justify-content-start border-0 p-0'>
                  <button
                    type='button'
                    className='btn btn-light-primary me-3'
                    onClick={() => handleshowCheckInModal()}
                  >
                    <i className='la la-plus fs-3' />
                    Select Assets
                  </button>
                  <CheckinModal
                    setPendingAssets={setPendingAssets}
                    selectedAssets={selectedAssets}
                    show={showCheckInModal}
                    handleClose={handleCloseCheckinModal}
                    handleCancel={handleCancel}
                    setPage={setPage}
                    setLimit={setLimit}
                    page={page}
                    limit={limit}
                    setAssignTo={setAssignTo}
                  />
                </div>
                {selectedAssets && selectedAssets?.length > 0 ? (
                  <>
                    <div className='separator border-2 my-10'></div>
                    <div className='d-flex justify-content-between align-items-center'>
                      <h4 className='mt-3'>Assets Pending Check-In</h4>
                      {deleteDataArr.length > 0 && (
                        <button
                          type='button'
                          className='btn btn-danger mb-2'
                          onClick={() => {
                            deleteSelectedAssests()
                          }}
                        >
                          Delete Selected
                        </button>
                      )}
                    </div>
                    <div className='checkout-listing-table mb-5 table-responsive pb-4'>
                      <table
                        id='kt_table_users'
                        className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                        role='table'
                      >
                        <thead>
                          <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                            <th role='columnheader' className='w-10px pe-2'>
                              <div className='me-3'>
                                <input
                                  type='checkbox'
                                  className='form-check-input custom-form-check-input'
                                  checked={selectAll}
                                  onChange={handleSelectAll}
                                />
                              </div>
                            </th>
                            <th role='columnheader' className='min-w-125px'>
                              Asset Tag ID
                            </th>
                            <th role='columnheader' className='min-w-125px'>
                              Description
                            </th>
                            <th role='columnheader' className='min-w-125px'>
                              Status
                            </th>
                            <th role='columnheader' className='min-w-125px'>
                              Assigned to
                            </th>
                            <th role='columnheader' className='min-w-125px'>
                              Site
                            </th>
                            <th role='columnheader' className='min-w-125px'>
                              Location
                            </th>
                            <th role='columnheader' className='min-w-125px'>
                              Lease to
                            </th>
                          </tr>
                        </thead>
                        <tbody className='text-gray-600 fw-bol' role='rowgroup'>
                          {selectedAssets?.length > 0 ? (
                            selectedAssets?.map((item: any) => {
                              return (
                                <tr role='row' key={item.id}>
                                  <td role='cell'>
                                    <div>
                                      <input
                                        type='checkbox'
                                        className='form-check-input custom-form-check-input'
                                        checked={selected.includes(item.id)}
                                        onChange={() => handleCheckboxChange(item.id)}
                                      />
                                    </div>
                                  </td>
                                  <td role='cell'>
                                    <div className='d-flex align-items-center'>
                                      <div className=' overflow-hidden me-3'>
                                        <button
                                          onClick={() => clickDeatilAsset(item.id)}
                                          className='d-block table-elipses text-btn'
                                        >
                                          {item?.assetTagId}
                                        </button>
                                      </div>
                                    </div>
                                  </td>
                                  <td role='cell'>
                                    <div className='d-flex align-items-center'>
                                      <div className=' overflow-hidden me-3'>
                                        <button
                                          onClick={() => clickDeatilAsset(item.id)}
                                          className='d-block table-elipses text-btn'
                                        >
                                          {item?.description}
                                        </button>
                                      </div>
                                    </div>
                                  </td>
                                  <td role='cell'>
                                    <div className='d-flex align-items-center'>
                                      <div
                                        className={`d-inline-block align-items-center px-3 py-2 rounded ${getAssetStatusClass(item.status)}`}
                                      >
                                        {item.status}
                                      </div>
                                    </div>
                                  </td>
                                  <td role='cell'>
                                    <div className='d-flex align-items-center'>
                                      <div className='overflow-hidden me-3'>
                                        {item?.assigneeType === 1 ? (
                                          <span>
                                            {`${item?.assignedPerson?.firstName || ''} ${
                                              item?.assignedPerson?.lastName || ''
                                            }`}
                                          </span>
                                        ) : item?.assigneeType === 2 ? (
                                          <span>
                                            {`${item?.assignedSite?.name || ''} ${
                                              item?.assignedSite?.name && item?.location?.location
                                                ? '/'
                                                : ''
                                            } ${item?.location?.location || ''}`}
                                          </span>
                                        ) : (
                                          <span></span>
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                  <td role='cell'>
                                    <div className='d-flex align-items-center'>
                                      <div className=' overflow-hidden me-3'>
                                        <Link to='/dummy-link'>{item?.site?.name}</Link>
                                      </div>
                                    </div>
                                  </td>
                                  <td role='cell'>
                                    <div className='d-flex align-items-center'>
                                      <div className='d-flex flex-column'>
                                        {item?.location?.location}
                                      </div>
                                    </div>
                                  </td>
                                  <td role='cell'>
                                    <div className='d-flex align-items-center'>
                                      <div className='d-flex flex-column'>
                                        {`${item?.leased?.firstName || ''}  ${item?.leased?.lastName || ''}`}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )
                            })
                          ) : (
                            <tr>
                              <td colSpan={12}>
                                <div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
                                  No records found
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className='separator border-2 my-10'></div>
                    <form onSubmit={formik.handleSubmit} noValidate className='form'>
                      <div className='row mb-3'>
                        <div className='col-xl-6'>
                          <div className='row mb-6 align-items-center'>
                            <label className='col-lg-3 col-form-label fw-bold fs-6'>
                              Check-in from
                            </label>
                            <div className='col-lg-9 fv-row'>
                              <div className='d-flex'>
                                <div className='m-2 form-check-custom'>
                                  <div>
                                    <input
                                      className='form-check-input custom-form-check-input'
                                      {...formik.getFieldProps('checkinType')}
                                      onClick={() => {
                                        setPersonCheckout(true)
                                        formik.setFieldValue('checkinType', 1)
                                        handleCheckinTypeChange()
                                      }}
                                      type='radio'
                                      value={1}
                                      checked={formik.values.checkinType == 1 ? true : false}
                                      name={'checkinType'}
                                      id='person'
                                    />
                                  </div>
                                  <label className='form-check-label fw-bold fs-6' htmlFor='person'>
                                    Person
                                  </label>
                                </div>
                                <div className='m-2 form-check-custom'>
                                  <div>
                                    <input
                                      className='form-check-input custom-form-check-input'
                                      type='radio'
                                      {...formik.getFieldProps('checkinType')}
                                      onClick={() => {
                                        setPersonCheckout(false)
                                        formik.setFieldValue('checkinType', 2)
                                        handleCheckinTypeChange()
                                      }}
                                      value={2}
                                      checked={formik.values.checkinType == 2 ? true : false}
                                      name={'checkinType'}
                                      id='location'
                                    />
                                  </div>
                                  <label
                                    className='form-check-label fw-bold fs-6'
                                    htmlFor='location'
                                  >
                                    Site / Location
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='row mb-6'>
                            <label className={'col-lg-3 col-form-label fw-bold fs-6 required'}>
                              Return Date
                            </label>
                            <div className='col-lg-9 fv-row'>
                              <div>
                                <input
                                  className='form-control form-control-solid'
                                  {...formik.getFieldProps('returnDate')}
                                  type='date'
                                  placeholder='Pick date rage'
                                  id='kt_daterangepicker_3'
                                  onChange={(e) => {
                                    formik.handleChange(e)
                                    handleReturnDate(e)
                                  }}
                                />
                              </div>
                              {formik.touched.returnDate && formik.errors.returnDate && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{formik.errors.returnDate}</div>
                                </div>
                              )}
                              {customErrors?.returnDate !== '' &&
                                personCheckout == (false || true) && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{customErrors?.returnDate}</div>
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className='row mb-6'>
                            <label
                              className={
                                formik.values.checkinType == 1
                                  ? 'col-lg-3 col-form-label fw-bold fs-6'
                                  : 'col-lg-3 col-form-label fw-bold fs-6 required'
                              }
                            >
                              Site
                            </label>
                            <div className='col-lg-9 fv-row'>
                              <select
                                className='form-select form-select-solid mb-3'
                                {...formik.getFieldProps('siteId')}
                                aria-label='Select example'
                                onChange={(e) => {
                                  handleSiteClick(e)
                                  formik.handleChange(e)
                                }}
                              >
                                <option value=''>Select Site</option>
                                {userSitesList?.sites?.map((item: any, idx: string) => {
                                  return <option value={item?.id}>{item?.name}</option>
                                })}
                              </select>
                              {formik.values.checkinType == 2 &&
                                formik.touched.siteId &&
                                formik.errors.siteId && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.siteId}</div>
                                  </div>
                                )}
                              {customErrors?.siteField !== '' && personCheckout == false && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{customErrors?.siteField}</div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='row mb-6'>
                            <label className='col-lg-3 col-form-label fw-bold fs-6'>Location</label>
                            <div className='col-lg-9 fv-row'>
                              <select
                                className='form-select form-select-solid mb-3'
                                {...formik.getFieldProps('locationId')}
                                aria-label='Select example'
                                onChange={(e) => {
                                  formik.handleChange(e)
                                  const value = e.target.value
                                  if (value === '') {
                                    formik.setFieldValue('locationId', undefined)
                                  }
                                }}
                              >
                                <option value=''>Select Location</option>
                                {siteId > 0 &&
                                  userLocationList?.locations?.map((item: any, idx: number) => {
                                    return <option value={item?.id}>{item?.location}</option>
                                  })}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className='col-xl-6'>
                          <div className='row mb-6'>
                            <label
                              className='col-lg-3 col-form-label fw-bold fs-6'
                              htmlFor='department'
                            >
                              Department
                            </label>
                            <div className='col-lg-9 fv-row'>
                              <select
                                className='form-select form-select-solid mb-3'
                                {...formik.getFieldProps('departmentId')}
                                onChange={(e) => {
                                  formik.handleChange(e)
                                  const value = e.target.value
                                  if (value === '') {
                                    formik.setFieldValue('departmentId', undefined)
                                  }
                                }}
                                id='department'
                                aria-label='Select example'
                              >
                                <option value=''>Select Department</option>
                                {userDepartmentList?.department?.map((item: any, idx: number) => {
                                  return <option value={item?.id}>{item?.department}</option>
                                })}
                              </select>
                            </div>
                          </div>
                          <div className='row mb-10'>
                            <label
                              className='col-lg-3 col-form-label fw-bold fs-6'
                              htmlFor='checkinNotes'
                            >
                              Check-in Notes
                            </label>
                            <div className='col-lg-9 fv-row'>
                              <textarea
                                className='form-control'
                                {...formik.getFieldProps('notes')}
                                id='checkinNotes'
                                cols={20}
                                rows={4}
                              ></textarea>
                              {formik.touched.notes && formik.errors.notes && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{String(formik.errors.notes)}</div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div
                            className={
                              personCheckout == true ? 'mb-10 row align-items-center' : 'd-none'
                            }
                          >
                            <div className='col-lg-3 col-form-label fw-bold fs-6'>
                              <div className='form-check form-check-custom  form-check-sm d-flex align-center mb-lg-0 mb-4'>
                                <div>
                                  <input
                                    className='form-check-input me-2'
                                    type='checkbox'
                                    id='flexRadioLg'
                                    checked={emailEnabled}
                                    onChange={() => setEmailEnabled(!emailEnabled)}
                                  />
                                </div>
                                <label
                                  className='form-label mb-0 fw-bold fs-6'
                                  htmlFor='flexRadioLg'
                                >
                                  Send Email
                                </label>
                              </div>
                            </div>
                            <div className='col-lg-9 fv-row'>
                              <div>
                                <input
                                  type='email'
                                  readOnly={true}
                                  {...formik.getFieldProps('emails')}
                                  className='form-control form-control-solid'
                                  placeholder='Email'
                                  value={personEmail}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className=' d-flex align-items-center justify-content-end'>
                        <button
                          onClick={handleCancel}
                          className='btn btn-light-primary   main-btn-style me-2'
                        >
                          Cancel
                        </button>
                        {personCheckout ? (
                          <button
                            type='submit'
                            className='btn btn-primary main-btn-style'
                            disabled={
                              customErrors.returnDate !== '' ||
                              customErrors.siteField !== '' ||
                              !formik.isValid
                            }
                          >
                            {!loading && 'Check-In'}
                            {loading && (
                              <span className='indicator-progress d-block'>
                                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            )}
                          </button>
                        ) : (
                          <button
                            type='submit'
                            className='btn btn-primary main-btn-style'
                            disabled={customErrors.siteField !== '' || !formik.isValid}
                          >
                            {!loading && 'Check-In'}
                            {loading && (
                              <span className='indicator-progress d-block'>
                                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            )}
                          </button>
                        )}
                      </div>
                    </form>
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
        <Loader loading={dataLoading} />
        <AssetMinimalDetailModal
        show={modalShow}
        onHide={() => {
          setLoading(false)
          setModalShow(false)
        }}
        assetId={assetId}
      />
      </div>
    </>
  )
}

export default Checkin
