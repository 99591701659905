import { createApi } from '@reduxjs/toolkit/query/react'
import { customFetchBaseQuery } from './Headers/CustomHeaders'

export const paymentApi = createApi({
  reducerPath: 'paymentApi',
  baseQuery: customFetchBaseQuery({ path: 'payment' }),
  tagTypes: ['Payments'],
  endpoints: (build) => ({
    createSubscription: build.mutation<any, any>({
      query: (body) => ({
        url: 'create',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'Payments' }],
    }),
    cancelSubscription: build.mutation<any, any>({
      query: (subscriptionId) => ({
        url: '/cancel',
        method: 'POST',
        body: subscriptionId,
      }),
      invalidatesTags: () => [{ type: 'Payments' }],
    }),
    getSubscription: build.query<any, any>({
      query: () => ({
        url: '/get',
        method: 'GET',
      }),
      providesTags: ['Payments'],
    }),
    getBillingInvoice: build.query<any, any>({
      query: () => ({
        url: '/invoice',
        method: 'GET',
      }),
      providesTags: ['Payments'],
    }),
  }),
})

export const {
  useCreateSubscriptionMutation,
  useCancelSubscriptionMutation,
  useGetSubscriptionQuery,
  useGetBillingInvoiceQuery,
} = paymentApi
