import moment from 'moment'

export const currentUserAssets = (currentUser, assetsData) => {
  const assets =
    currentUser?.userType === '2'
      ? currentUser?.email
        ? assetsData?.userAssets?.filter(
            (asset) => asset?.assetstatus?.user?.email === currentUser?.email
          )
        : null
      : assetsData?.userAssets
  return assets
}

export const getCurrentDate = () => {
  const date = new Date()
  return date.toISOString().split('T')[0]
}
export const tinyMYCAPIKEY = process.env.REACT_APP_TINYMCE_API_KEY
export const defaultDateFormat = 'DD-MM-YYYY'
export const defaultTimeFormat = 'hh:mm A'
export const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9])(?!.*\s).{8,}$/
export const imageMimeType = /image\/(png|jpg|jpeg)/i
export const emailRegex = /^[\w+-.]+@([\w-]+\.)+[\w-]{2,4}$/
export const nameRegex = /^[a-zA-Z ]*$/
export const titleRegex = /^[a-zA-Z\s!@#$%^&*()\-_+=\[{\]};:/'"|\\,.<>?|]*$/
export const companyName = /^[a-zA-Z0-9 @#$&*()_\-[\]:"',./|]*$/
export const addressRegex =
  /^\s*[A-Za-z0-9!@#$%^&*()\-_+=[{\]};:'"|\\,./<>?]+(?:\s+[A-Za-z0-9!@#$%^&*()\-_+=[{\]};:'"|\\,./<>?]+)*\s*$/
export const postalRegex = /^[0-9]+$/
export const multiSpace = /\s+/g
export const digitsRegex = /^\s*\d+\s*$/
export const costRegex = /^\s*\d+(\.\d+)?\s*$/
export const phoneRegex = /^(?:\+\d{10,15}|\d{10,15})$/
export const decimalPlacesRegex = /^\d*\.?\d{0,2}$/
export const priceRegex = /^\d{0,15}$/
export const mailCheck = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
export const commaRemoval = /^,|,$/g
export const baseURL = process.env.REACT_APP_FRONT_URL
export const secretKey = process.env.SECURE_SECRET_KEY
export const assetLimit = 1000

export const nonNumericKeys = (e) => {
  if (e.key === 'e' || e.key === '-' || e.key === '+' || e.key === ' ') {
    e.preventDefault()
  }
}
export const nameKeys = {
  searchField: 'searchField',
  status: 'status',
  customDate: 'customDate',
  recordCount: 'recordCount',
  multiCheckSelectName: 'multicheckSelect',
  quickDate: 'quickDate',
  restricted: 'restricted',
}
export const setupKeys = {
  asset: 'Asset',
  category: 'Category',
  customer: 'Customer',
  department: 'Department',
  location: 'Location',
  person: 'Person',
  site: 'Site',
  group: 'Group',
}

export const originalName = (fileName) => {
  return fileName.split('document-')[1]
}

export const importDateFormat = (date) => {
  return moment(date).format('MM/DD/YYYY')
}
export const dateFormat = (date, format = 'DD-MM-YYYY') => {
  return moment(date).format(format)
}
export const assetFieldCheck = [
  'Category',
  'Site',
  'Location',
  'Department',
  'Assigned_To',
  'Event_Date',
  'Event_Due_Date',
  'Event_Notes',
  'Leased_To',
  'Status',
  'Expires',
  'Frequency',
  'Is_Repeating',
  'Maintenance_By',
  'Maintenance_Completion_Date',
  'Maintenance_Cost',
  'Maintenance_Detail',
  'Recur_on_every',
  'Maintenance_Status',
  'Title',
  'Length',
  'Notes',
  'Warranty_Created_By',
  'Warranty_Status',
]
export const eventFieldsCheck = [
  'Assigned_To',
  'Event_Date',
  'Event_Due_Date',
  'Event_Notes',
  'Leased_To',
  'Status',
]
export const linkingFieldsCheck = ['Category', 'Site', 'Location', 'Department']
export const maintenanceFieldsCheck = [
  'Expires',
  'Frequency',
  'Is_Repeating',
  'Maintenance_By',
  'Maintenance_Completion_Date',
  'Maintenance_Cost',
  'Maintenance_Detail',
  'Recur_on_every',
  'Maintenance_Status',
  'Title',
]
export const warrantyFieldsCheck = [
  'Expires',
  'Length',
  'Notes',
  'Warranty_Created_By',
  'Warranty_Status',
]
export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
export const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
export const dateOptions = Array.from({ length: 31 }, (_, index) => (index + 1).toString())
export const searchFieldsOptionsPersons = [
  { label: 'All', value: '*' },
  { value: 'firstName', label: 'First Name' },
  { value: 'lastName', label: 'Last Name' },
  { value: 'employeeId', label: 'Employee ID' },
  { value: 'title', label: 'Job Title' },
  { value: 'email', label: 'Email' },
  { value: 'phone', label: 'Phone' },
]
export const searchFieldsOptionsCustomer = [
  { label: 'All', value: '*' },
  { value: 'firstName', label: 'First Name' },
  { value: 'lastName', label: 'Last Name' },
  { value: 'email', label: 'Email' },
  { value: 'phone', label: 'Phone' },
  { value: 'company', label: 'Company' },
]
export const countryCode = {
  PAK: '+92',
}
export const wizardArray = [
  {
    path: 'company',
    image: (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
        <path d='M0 8a8 8 0 1116 0A8 8 0 010 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 005.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 01.64-1.539 6.7 6.7 0 01.597-.933A7.025 7.025 0 002.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 00-.656 2.5h2.49zM4.847 5a12.5 12.5 0 00-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 00-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 00.337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 01-.597-.933A9.268 9.268 0 014.09 12H2.255a7.024 7.024 0 003.072 2.472zM3.82 11a13.652 13.652 0 01-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0013.745 12H11.91a9.27 9.27 0 01-.64 1.539 6.688 6.688 0 01-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 01-.312 2.5zm2.802-3.5a6.959 6.959 0 00-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 00-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 00-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z'></path>
      </svg>
    ),
    title: '1. Company',
  },
  {
    path: 'sites',
    image: (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
        <path d='M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 018 14.58a31.481 31.481 0 01-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0110 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 002 6c0 4.314 6 10 6 10z'></path>
        <path d='M8 8a2 2 0 110-4 2 2 0 010 4zm0 1a3 3 0 100-6 3 3 0 000 6z'></path>
      </svg>
    ),
    title: '2. Sites',
  },
  {
    path: 'location',
    image: (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
        <path
          fill-rule='evenodd'
          d='M3.1 11.2a.5.5 0 01.4-.2H6a.5.5 0 010 1H3.75L1.5 15h13l-2.25-3H10a.5.5 0 010-1h2.5a.5.5 0 01.4.2l3 4a.5.5 0 01-.4.8H.5a.5.5 0 01-.4-.8l3-4z'
        ></path>
        <path
          fill-rule='evenodd'
          d='M8 1a3 3 0 100 6 3 3 0 000-6zM4 4a4 4 0 114.5 3.969V13.5a.5.5 0 01-1 0V7.97A4 4 0 014 3.999z'
        ></path>
      </svg>
    ),
    title: '3. Locations',
  },
  {
    path: 'categories',
    image: (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
        <path d='M5 10.5a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5zm0-2a.5.5 0 01.5-.5h5a.5.5 0 010 1h-5a.5.5 0 01-.5-.5zm0-2a.5.5 0 01.5-.5h5a.5.5 0 010 1h-5a.5.5 0 01-.5-.5zm0-2a.5.5 0 01.5-.5h5a.5.5 0 010 1h-5a.5.5 0 01-.5-.5z'></path>
        <path d='M3 0h10a2 2 0 012 2v12a2 2 0 01-2 2H3a2 2 0 01-2-2v-1h1v1a1 1 0 001 1h10a1 1 0 001-1V2a1 1 0 00-1-1H3a1 1 0 00-1 1v1H1V2a2 2 0 012-2z'></path>
        <path d='M1 5v-.5a.5.5 0 011 0V5h.5a.5.5 0 010 1h-2a.5.5 0 010-1H1zm0 3v-.5a.5.5 0 011 0V8h.5a.5.5 0 010 1h-2a.5.5 0 010-1H1zm0 3v-.5a.5.5 0 011 0v.5h.5a.5.5 0 010 1h-2a.5.5 0 010-1H1z'></path>
      </svg>
    ),
    title: '4. Categories',
  },
]

export const convertToJSON = (photos) => {
  switch (typeof photos) {
    case 'string': {
      const array = JSON.parse(photos)
      return array[0] || ''
    }
    default:
      return photos[0]
  }
}

export const getAssetStatusClass = (status) => {
  if (!status) return ''

  const statusClassMap = {
    Available: 'bg-light-green',
    Broken: 'bg-light-red',
    'Checked out': 'bg-light-blue',
    Disposed: 'bg-light-pink',
    Donated: 'bg-light-orange',
    Leased: 'bg-light-blue',
    'Lost/Missing': 'bg-light-grey',
    Sold: 'bg-light-yellow',
    'Under repair': 'bg-light-purple',
  }

  return statusClassMap[status] || ''
}

export const importOptions = [
  { value: 'assets', label: 'Assets', permission: 'importAsset' },
  { value: 'categories', label: 'Categories', permission: 'importCategory' },
  { value: 'customers', label: 'Customers', permission: 'importCustomer' },
  { value: 'departments', label: 'Departments', permission: 'importDepartment' },
  { value: 'locations', label: 'Locations', permission: 'importSite' },
  { value: 'maintenances', label: 'Maintenances', permission: 'importMaintenance' },
  { value: 'person', label: 'Persons/Employees', permission: 'importPerson' },
  { value: 'sites', label: 'Sites', permission: 'importSite' },
  { value: 'warranties', label: 'Warranties', permission: 'importWarranties' },
]

export const exportOptions = [
  { value: 'exportAsset', label: 'Assets', permission: 'exportAsset' },
  { value: 'exportCategory', label: 'Categories', permission: 'exportCategory' },
  { value: 'exportCustomer', label: 'Customers', permission: 'exportCustomer' },
  { value: 'exportDepartment', label: 'Departments', permission: 'exportDepartment' },
  { value: 'exportLocation', label: 'Locations', permission: 'exportSite' },
  { value: 'exportMaintenance', label: 'Maintenances', permission: 'exportMaintenance' },
  { value: 'exportPerson', label: 'Persons/Employees', permission: 'exportPerson' },
  { value: 'exportSite', label: 'Sites', permission: 'exportSite' },
  { value: 'exportWarranty', label: 'Warranties', permission: 'exportWarranties' },
]
export const convertFieldsToJSON = (details) => {
  if (typeof details !== 'object') {
    return JSON.parse(details)
  } else {
    return details
  }
}

import CryptoJS from 'crypto-js'
import { useGetSubscriptionQuery } from 'App/Modules/Services/Payments'

export const privacyKey = 'vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3'
export const encrypt = (text) => {
  const iv = CryptoJS.lib.WordArray.random(16)
  const encrypted = CryptoJS.AES.encrypt(text, CryptoJS.enc.Utf8.parse(privacyKey), {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  })

  return {
    iv: iv.toString(CryptoJS.enc.Hex),
    content: encrypted.toString(),
  }
}

export const decrypt = (hash) => {
  if (hash) {
    const iv = CryptoJS.enc.Hex.parse(hash?.iv)
    const decrypted = CryptoJS.AES.decrypt(hash.content, CryptoJS.enc.Utf8.parse(privacyKey), {
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    })
    return decrypted.toString(CryptoJS.enc.Utf8)
  }
  return
}


