import { useFormik } from 'formik'
import Modal from 'react-bootstrap/Modal'
import { initialCustomers } from '../Core/Model'
import * as Yup from 'yup'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { checkUserUnauthorized, useAuth } from '../../../../Auth'
import { useAddCustomerMutation, useUpdateCustomerMutation } from '../../../../Services/Customers'
import { emailRegex, nameRegex, phoneRegex } from 'config'
import { countriesList } from 'Data/Countries'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const CustomersSechma = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .matches(nameRegex, 'Only letters, numbers and no extra spaces allowed')
    .min(3, 'Minimum 3 letters')
    .max(20, 'First name should not exceed 20 characters')
    .required('First name is required'),
  lastName: Yup.string()
    .trim()
    .matches(nameRegex, 'Only letters, numbers and no extra spaces allowed')
    .min(3, 'Minimum 3 letters')
    .max(20, 'Last name should not exceed 20 characters')
    .required('Last name is required'),
  email: Yup.string()
    .matches(emailRegex, 'Invalid email address')
    .max(50, 'Maximum 50 characters')
    .required('Email is required'),
  phone: Yup.string()
    .trim()
    .min(10, 'Number should be 10 or more digits')
    .max(15, 'Number should be less then or equal to 15 digits')
    .matches(phoneRegex, 'Only digits and plus sign on start are allowed')
    .nullable(),
  cell: Yup.string()
    .trim()
    .min(10, 'Number should be 10 or more digits')
    .max(15, 'Number should be less then or equal to 15 digits')
    .matches(phoneRegex, 'Only digits and plus sign on start are allowed')
    .nullable(),
  company: Yup.string().max(50, 'Company must be no more than 50 characters.').nullable(),
  city: Yup.string().max(50, 'City must be no more than 50 characters.').nullable(),
  address1: Yup.string().max(50, 'Address1 must be no more than 50 characters.').nullable(),
  address2: Yup.string().max(50, 'Address2 must be no more than 50 characters.').nullable(),
  state: Yup.string().max(50, 'State must be no more than 50 characters.').nullable(),
  zip: Yup.string().trim().max(15, 'Zip code should be less then or equal to 15 digits').nullable(),
  notes: Yup.string().max(250, 'Notes must be no more than 250 characters.').nullable(),
})

const CustomersModal = (props: any) => {
  const { currentUser, saveAuth, setCurrentUser } = useAuth()
  const [skip, setSkip] = useState<boolean>(false)
  const [addCustomer, { isLoading: isAdding }] = useAddCustomerMutation()
  const [updateCustomer, { isLoading: isUpdating }] = useUpdateCustomerMutation()

  useEffect(() => {
    setSkip(false)
  }, [])

  useEffect(() => {
    formik.resetForm()
    if (props.customerId) {
      formik.setValues({
        firstName: props?.CustomerData?.customer?.firstName,
        lastName: props?.CustomerData?.customer?.lastName,
        company: props?.CustomerData?.customer?.company,
        address1: props?.CustomerData?.customer?.address1,
        address2: props?.CustomerData?.customer?.address2,
        city: props?.CustomerData?.customer?.city,
        state: props?.CustomerData?.customer?.state,
        zip: props?.CustomerData?.customer?.zip,
        country: props?.CustomerData?.customer?.country,
        phone: props?.CustomerData?.customer?.phone || '',
        cell: props?.CustomerData?.customer?.cell || '',
        email: props?.CustomerData?.customer?.email,
        notes: props?.CustomerData?.customer?.notes || '',
      })
    }
  }, [props.customerId, props.CustomerData])

  const formik = useFormik({
    initialValues: { ...initialCustomers },
    validationSchema: CustomersSechma,
    onSubmit: async (values) => {
      if (currentUser) {
        try {
          const data = props?.customerId
            ? await updateCustomer({ id: props.customerId, values }).unwrap()
            : await addCustomer(values).unwrap()
          if (data) {
            toast.dismiss()
            if (data?.success) {
              toast.success(data?.message)
              formik.resetForm()
              props.handleClose()
            } else {
              toast.error(data?.message)
            }
          }
        } catch (error: any) {
          toast.error(error?.data?.message)
          checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
          formik.setSubmitting(false)
        }
      }
    },
  })

  const handleClose = () => {
    formik.resetForm()
    props.handleClose()
  }

  return (
    <>
      <Modal
        className='custom-modal-sm'
        show={props.show}
        onHide={props.handleClose}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title>{props?.customerId ? 'Update Customer' : 'Add Customer'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit} noValidate className='form' autoComplete='on'>
            <div className='inner-site-modal'>
              <Row className='mb-4'>
                <Col lg='3'>
                  <label htmlFor='firstName' className='col-form-label required fw-bold fs-6 mb-2'>
                    First Name
                  </label>
                </Col>
                <Col lg='9' className='fv-row'>
                  <div>
                    <input
                      id='firstName'
                      {...formik.getFieldProps('firstName')}
                      type='text'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                    />
                  </div>
                  {formik.touched.firstName && formik.errors.firstName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.firstName}</div>
                    </div>
                  )}
                </Col>
              </Row>
              <Row className='mb-4'>
                <Col lg='3'>
                  <label htmlFor='lastName' className='col-form-label required fw-bold fs-6 mb-2'>
                    Last Name
                  </label>
                </Col>
                <Col lg='9' className='fv-row'>
                  <div>
                    <input
                      id='lastName'
                      {...formik.getFieldProps('lastName')}
                      type='text'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                    />
                  </div>
                  {formik.touched.lastName && formik.errors.lastName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.lastName}</div>
                    </div>
                  )}
                </Col>
              </Row>
              <Row className='mb-4'>
                <Col lg='3'>
                  <label htmlFor='company' className='col-form-label fw-bold fs-6 mb-2'>
                    Company
                  </label>
                </Col>
                <Col lg='9' className='fv-row'>
                  <div>
                    <input
                      id='company'
                      {...formik.getFieldProps('company')}
                      type='text'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                    />
                  </div>
                  {formik.touched.company && formik.errors.company && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.company}</div>
                    </div>
                  )}
                </Col>
              </Row>
              <Row className='mb-4'>
                <Col lg='3'>
                  <label htmlFor='address1' className='col-form-label fw-bold fs-6 mb-2'>
                    Address1
                  </label>
                </Col>
                <Col lg='9' className='fv-row'>
                  <input
                    id='address1'
                    {...formik.getFieldProps('address1')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                  />
                  {formik.touched.address1 && formik.errors.address1 && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.address1}</div>
                    </div>
                  )}
                </Col>
              </Row>
              <Row className='mb-4'>
                <Col lg='3'>
                  <label htmlFor='address2' className='col-form-label fw-bold fs-6 mb-2'>
                    Address2
                  </label>
                </Col>
                <Col lg='9' className='fv-row'>
                  <input
                    id='address2'
                    {...formik.getFieldProps('address2')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                  />
                  {formik.touched.address2 && formik.errors.address2 && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.address2}</div>
                    </div>
                  )}
                </Col>
              </Row>
              <Row className='mb-4'>
                <Col lg='3'>
                  <label htmlFor='city' className='col-form-label fw-bold fs-6 mb-2'>
                    City
                  </label>
                </Col>
                <Col lg='9' className='fv-row'>
                  <input
                    id='city'
                    {...formik.getFieldProps('city')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                  />
                  {formik.touched.city && formik.errors.city && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.city}</div>
                    </div>
                  )}
                </Col>
              </Row>
              <Row className='mb-4'>
                <Col lg='3'>
                  <label htmlFor='state' className='col-form-label fw-bold fs-6 mb-2'>
                    State
                  </label>
                </Col>
                <Col lg='9' className='fv-row'>
                  <input
                    id='state'
                    {...formik.getFieldProps('state')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                  />
                  {formik.touched.state && formik.errors.state && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.state}</div>
                    </div>
                  )}
                </Col>
              </Row>
              <Row className='mb-4'>
                <Col lg='3'>
                  <label htmlFor='zip' className='col-form-label fw-bold fs-6 mb-2'>
                    Zip
                  </label>
                </Col>
                <Col lg='9' className='fv-row'>
                  <input
                    id='zip'
                    {...formik.getFieldProps('zip')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                  />
                  {formik.touched.zip && formik.errors.zip && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.zip}</div>
                    </div>
                  )}
                </Col>
              </Row>
              <Row className='mb-4'>
                <Col lg='3'>
                  <label htmlFor='country' className='col-form-label fw-bold fs-6'>
                    Country
                  </label>
                </Col>
                <Col lg='9' className='fv-row'>
                  <select
                    id='country'
                    className='form-select form-control form-control-solid form-select-solid'
                    {...formik.getFieldProps('country')}
                  >
                    <option selected> Select country</option>
                    {countriesList?.map((item: any, idx: number) => {
                      return (
                        <option key={idx} value={item.code}>
                          {item.name}
                        </option>
                      )
                    })}
                  </select>
                </Col>
              </Row>
              <Row className='mb-4'>
                <Col lg='3'>
                  <label htmlFor='phone' className='col-form-label fw-bold fs-6 mb-2'>
                    Phone
                  </label>
                </Col>
                <Col lg='9' className='fv-row'>
                  <input
                    id='phone'
                    {...formik.getFieldProps('phone')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.phone}</div>
                    </div>
                  )}
                </Col>
              </Row>
              <Row className='mb-4'>
                <Col lg='3'>
                  <label htmlFor='cell' className='col-form-label fw-bold fs-6 mb-2'>
                    Cell
                  </label>
                </Col>
                <Col lg='9' className='fv-row'>
                  <input
                    id='cell'
                    {...formik.getFieldProps('cell')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                  />
                  {formik.touched.cell && formik.errors.cell && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.cell}</div>
                    </div>
                  )}
                </Col>
              </Row>
              <Row className='mb-4'>
                <Col lg='3'>
                  <label htmlFor='email' className='col-form-label required fw-bold fs-6 mb-2'>
                    Email
                  </label>
                </Col>
                <Col lg='9' className='fv-row'>
                  <input
                    id='email'
                    {...formik.getFieldProps('email')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.email}</div>
                    </div>
                  )}
                </Col>
              </Row>
              <Row className='mb-4'>
                <Col lg='3'>
                  <label htmlFor='notes' className='col-form-label fw-bold fs-6 mb-2'>
                    Notes
                  </label>
                </Col>
                <Col lg='9' className='fv-row'>
                  <textarea
                    id='notes'
                    {...formik.getFieldProps('notes')}
                    rows={3}
                    className='form-control form-control-solid mb-3 mb-lg-0'
                  />
                </Col>
                {formik.touched.notes && formik.errors.notes && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.notes}</div>
                  </div>
                )}
              </Row>
            </div>
            <div className='text-end pt-2'>
              <button
                type='button'
                className='btn btn-light-primary   main-btn-style me-2'
                onClick={handleClose}
              >
                Close{' '}
              </button>
              <button
                type='submit'
                className='btn btn-primary main-btn-style'
                disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
              >
                {!isAdding && !isUpdating && (
                  <span className='indicator-label'>
                    {props?.updateCustomer ? 'Update Customer' : 'Add Customer'}
                  </span>
                )}
                {(isAdding || isUpdating) && (
                  <span className='indicator-progress d-block'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CustomersModal
