import Modal from 'react-bootstrap/Modal'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { defaultMaintenanceDetail as initialValues } from '../../../../../Maintenance/Core/Model'
import { useAddMaintenanceMutation } from 'App/Modules/Services/Maintenance'
import { useState } from 'react'
import { dateOptions, days, decimalPlacesRegex, months, nonNumericKeys } from 'config'
import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const accountDetailsSchema = Yup.object().shape({
  title: Yup.string()
    .required('Title is required')
    .max(20, 'Title should not exceed 20 characters')
    .test('no-empty-spaces', 'Title should not be empty', (value = '') => {
      return !/^\s*$/.test(value)
    }),
})

function AddMaintenanceModel(props) {
  const { saveAuth, setCurrentUser } = useAuth()
  const [addMaintenance, { isLoading: isAdding }] = useAddMaintenanceMutation()
  const [repeatingMaintenance, setRepeatingMaintenance] = useState(false)
  const [repeatingFrequency, setRepeatingFrequency] = useState('')
  const today = new Date().toISOString().slice(0, 10)
  const formik = useFormik({
    initialValues: { ...initialValues },
    validationSchema: accountDetailsSchema,
    onSubmit: async (values) => {
      try {
        const costOnValue: any = values?.cost
        const parsedCostnEvery = costOnValue === '' ? null : costOnValue
        const data = {
          title: values?.title,
          details: values?.details === '' ? null : values?.details,
          dueDate: values?.dueDate === '' ? null : values?.dueDate,
          maintenanceBy: values?.maintenanceBy === '' ? null : values?.maintenanceBy,
          status: values?.status === '' ? null : values?.status,
          dateCompleted: values?.dateCompleted === '' ? null : values?.dateCompleted,
          cost: parsedCostnEvery,
          repeating: repeatingFrequency ? true : false,
          frequency: repeatingFrequency ? repeatingFrequency : null,
          recurOnEvery: values?.recurOnEvery,
          recurOn: values?.recurOn,
          assetId: [props.assetId],
        }
        if (data.frequency) {
          switch (data.frequency.toLowerCase()) {
            case 'daily':
              data.frequency = '1'
              break
            case 'weekly':
              data.frequency = '2'
              break
            case 'monthly':
              data.frequency = '3'
              break
            case 'yearly':
              data.frequency = '4'
              break
            default:
              data.frequency = ''
              break
          }
        }
        const result = await addMaintenance(data).unwrap()
        if (result.success) {
          toast.success(result?.message)
          props.setShowEditModal(false)
          props.setShowModal(true)
          formik.resetForm()
        }
      } catch (error: any) {
        toast.error(error?.data?.message)
        checkUserUnauthorized(error, saveAuth, setCurrentUser, toast)
      }
    },
  })

  const handleFormSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    formik.submitForm()
  }
  const handleClose = () => {
    formik.resetForm()
    props.onHide()
  }
  return (
    <>
      <Modal
        className='px-2'
        size='lg'
        {...props}
        aria-labelledby='contained-modal-title-vcenter'
        backdrop='static'
      >
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title>Add Maintenance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='inner-site-modal'>
              <Row className='mb-4'>
                <Col lg='3'>
                  <label className='col-form-label required fw-bold fs-6 mb-2'> Title </label>
                </Col>
                <Col lg='9'>
                  <div className='fv-row'>
                    <div>
                      <input
                        {...formik.getFieldProps('title')}
                        onChange={(e) => {
                          formik.handleChange(e)
                        }}
                        type='text'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        autoComplete='off'
                      />
                    </div>
                    {formik.touched.title && formik.errors.title && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.title}</div>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row className='mb-4'>
                <Col lg='3'>
                  <label className='col-form-label fw-bold fs-6'>Details</label>
                </Col>
                <Col lg='9'>
                  <div className='fv-row'>
                    <textarea
                      {...formik.getFieldProps('details')}
                      onChange={(e) => {
                        formik.handleChange(e)
                      }}
                      rows={3}
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      autoComplete='off'
                    />
                  </div>
                </Col>
              </Row>
              <Row className='mb-6'>
                <Col lg='3'>
                  <label className='col-form-label  fw-bold fs-6'>Due Date</label>
                </Col>
                <Col lg='9'>
                  <div className='fv-row'>
                    <div className='input-group'>
                      <input
                        type='date'
                        className='form-control  form-control-solid'
                        aria-label='Amount (to the nearest dollar)'
                        {...formik.getFieldProps('dueDate')}
                        onChange={(e) => {
                          formik.handleChange(e)
                        }}
                        min={today}
                      />
                      <span className='input-group-text border-0'>
                        <i className='fa fa-calendar'></i>
                      </span>
                    </div>
                    {formik.touched.dueDate && formik.errors.dueDate && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.dueDate}</div>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row className='mb-4'>
                <Col lg='3'>
                  <label className='col-form-label fw-bold fs-6'> Maintenance By </label>
                </Col>
                <Col lg='9'>
                  <div className='fv-row'>
                    <div>
                      <input
                        {...formik.getFieldProps('maintenanceBy')}
                        onChange={(e) => {
                          formik.handleChange(e)
                        }}
                        type='text'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        autoComplete='off'
                      />
                    </div>
                    {formik.touched.maintenanceBy && formik.errors.maintenanceBy && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.maintenanceBy}</div>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row className='mb-4'>
                <Col lg='3'>
                  <label className='col-form-label fw-bold fs-6'>
                    <span>Maintenance Status</span>
                  </label>
                </Col>
                <Col lg='9'>
                  <div className='fv-row'>
                    <select
                      className='form-select form-control form-control-solid form-select-solid  '
                      {...formik.getFieldProps('status')}
                      onChange={(e) => {
                        formik.handleChange(e)
                      }}
                    >
                      <option>Select ...</option>
                      <option value='Scheduled'>Scheduled</option>
                      <option value='In Progress'>In progress</option>
                      <option value='On Hold'>On hold</option>
                      <option value='Cancelled'>Cancelled</option>
                      <option value='Completed'>Completed</option>
                    </select>
                  </div>
                </Col>
              </Row>
              <Row className='mb-4'>
                <Col lg='3'>
                  <label className='col-form-label fw-bold fs-6'>Date Completed</label>
                </Col>
                <Col lg='9'>
                  <div className='fv-row'>
                    <div className='input-group'>
                      <input
                        type='date'
                        className='form-control  form-control-solid'
                        {...formik.getFieldProps('dateCompleted')}
                        onChange={(e) => {
                          formik.handleChange(e)
                        }}
                        min={
                          formik.values.dueDate
                            ? new Date(formik.values.dueDate).toISOString().split('T')[0]
                            : new Date().toISOString().split('T')[0]
                        }
                      />
                      <span className='input-group-text border-0'>
                        <i className='fa fa-calendar'></i>
                      </span>
                    </div>
                    {formik.touched.dateCompleted && formik.errors.dateCompleted && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.dateCompleted}</div>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row className='mb-4'>
                <Col lg='3'>
                  <label className='col-form-label fw-bold fs-6'>Maintenance Cost</label>
                </Col>
                <Col lg='9'>
                  <div className='fv-row'>
                    <div className='input-group currency-field'>
                      <span className='input-group-text'>{props.currencyLogo}</span>
                      <input
                        type='number'
                        min={'1'}
                        className='form-control  form-control-solid'
                        aria-label='Amount (to the nearest dollar)'
                        {...formik.getFieldProps('cost')}
                        onChange={(e) => {
                          const value = e.target.value
                          if (decimalPlacesRegex.test(value)) {
                            formik.setFieldValue('cost', value)
                          }
                        }}
                        onKeyDown={(e) => {
                          nonNumericKeys(e)
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className='mb-4'>
                <Col lg='3'>
                  <label className='col-form-label  fw-bold fs-6'>Repeating</label>
                </Col>
                <Col lg='9'>
                  <div className='fv-row'>
                    <div className='d-flex'>
                      <div className='m-2 form-check-custom fw-bold'>
                        <div>
                          <input
                            className='form-check-input custom-form-check-input'
                            {...formik.getFieldProps('frequency')}
                            onClick={() => {
                              setRepeatingMaintenance(true)
                              setRepeatingFrequency('daily')
                            }}
                            type='checkbox'
                            value={'yes'}
                            checked={repeatingMaintenance == false ? false : true}
                            id='repeatingYes'
                          />
                        </div>
                        <label className='form-check-label' htmlFor='repeatingYes'>
                          Yes
                        </label>
                      </div>
                      <div className='m-2 form-check-custom fw-bold'>
                        <div>
                          <input
                            className='form-check-input custom-form-check-input'
                            {...formik.getFieldProps('maintenanceReapting')}
                            onClick={() => {
                              setRepeatingMaintenance(false)
                              setRepeatingFrequency('')
                            }}
                            value={'no'}
                            checked={repeatingMaintenance === false ? true : false}
                            type='checkbox'
                            id='repeatingNo'
                          />
                        </div>
                        <label className='form-check-label' htmlFor='repeatingNo'>
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <div className={repeatingMaintenance ? ' mb-6 align-items-center' : 'd-none'}>
                  <Col lg='3'>
                    <label className='col-form-label  fw-bold fs-6' htmlFor='maintenanceFrequency'>
                      {' '}
                      Frequency
                    </label>
                  </Col>
                  <Col lg='9'>
                    <div className='fv-row'>
                      <div className='d-flex'>
                        <div className='m-2 form-check-custom fw-bold'>
                          <div>
                            <input
                              className='form-check-input custom-form-check-input'
                              {...formik.getFieldProps('frequency')}
                              onClick={() => {
                                setRepeatingFrequency('daily')
                              }}
                              type='checkbox'
                              value={'daily'}
                              checked={repeatingFrequency == 'daily' ? true : false}
                              name={'frequency'}
                              id='dailyFrequency'
                            />
                          </div>
                          <label className='form-check-label' htmlFor='dailyFrequency'>
                            Daily
                          </label>
                        </div>
                        <div className='m-2 form-check-custom fw-bold'>
                          <div>
                            <input
                              className='form-check-input custom-form-check-input'
                              {...formik.getFieldProps('frequency')}
                              onClick={() => {
                                setRepeatingFrequency('weekly')
                              }}
                              type='checkbox'
                              value={'weekly'}
                              checked={repeatingFrequency == 'weekly' ? true : false}
                              name={'frequency'}
                              id='weeklyFrequency'
                            />
                          </div>
                          <label className='form-check-label' htmlFor='weeklyFrequency'>
                            Weekly
                          </label>
                        </div>
                        <div className='m-2 form-check-custom fw-bold'>
                          <div>
                            <input
                              className='form-check-input custom-form-check-input'
                              {...formik.getFieldProps('frequency')}
                              onClick={() => {
                                setRepeatingFrequency('monthly')
                              }}
                              type='checkbox'
                              value={'monthly'}
                              checked={repeatingFrequency == 'monthly' ? true : false}
                              name={'frequency'}
                              id='monthlyFrequency'
                            />
                          </div>
                          <label className='form-check-label' htmlFor='monthlyFrequency'>
                            Monthly
                          </label>
                        </div>
                        <div className='m-2 form-check-custom fw-bold'>
                          <div>
                            <input
                              className='form-check-input custom-form-check-input'
                              {...formik.getFieldProps('frequency')}
                              onClick={() => {
                                setRepeatingFrequency('yearly')
                              }}
                              type='checkbox'
                              value={'yearly'}
                              checked={repeatingFrequency == 'yearly' ? true : false}
                              name={'frequency'}
                              id='yearlyFrequency'
                            />
                          </div>
                          <label className='form-check-label' htmlFor='yearlyFrequency'>
                            Yearly
                          </label>
                        </div>
                      </div>
                    </div>
                  </Col>
                </div>
              </Row>
              {repeatingFrequency == 'weekly' ||
                repeatingFrequency == 'monthly' ||
                (repeatingFrequency == 'yearly' && (
                  <Row>
                    <div className={repeatingMaintenance ? 'mb-6' : 'd-none'}>
                      <Col lg='3'>
                        <label className='col-form-label  fw-bold fs-6'>Recur on:</label>
                      </Col>
                    </div>
                  </Row>
                ))}
              <Row>
                <div
                  className={
                    repeatingMaintenance &&
                    (repeatingFrequency == 'weekly' || repeatingFrequency == 'monthly')
                      ? ' mb-6'
                      : 'd-none'
                  }
                >
                  <Col lg='3'>
                    <label className='col-form-label  fw-bold fs-6' htmlFor='recurOnEvery'>
                      Every
                    </label>
                  </Col>
                  <Col lg='9'>
                    <div className={repeatingMaintenance ? 'fv-row' : 'd-none'}>
                      <div className='input-group'>
                        <input
                          className='form-control  form-control-solid'
                          {...formik.getFieldProps('recurOnEvery')}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                          id='recurOnEvery'
                        />
                      </div>
                    </div>
                  </Col>
                </div>
              </Row>
              <Row>
                <div
                  className={
                    repeatingMaintenance && repeatingFrequency == 'yearly' ? ' mb-6' : 'd-none'
                  }
                >
                  <Col lg='3'>
                    <label
                      className={repeatingFrequency ? 'col-form-label  fw-bold fs-6' : 'd-none'}
                      htmlFor='recurOnEvery'
                    >
                      {' '}
                      Every
                    </label>
                  </Col>
                  <Col lg='9'>
                    <div className={repeatingFrequency ? 'fv-row' : 'd-none'}>
                      <div className='input-group'>
                        <select
                          {...formik.getFieldProps('recurOnEvery')}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                          className='form-select form-control form-control-solid form-select-solid  '
                          id='recurOnEvery'
                        >
                          <option value=''>Select Month</option>
                          {months.map((month) => (
                            <option key={month} value={month.toLowerCase()}>
                              {month}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </Col>
                </div>
              </Row>
              <Row>
                <div
                  className={
                    repeatingMaintenance && repeatingFrequency == 'weekly' ? ' mb-6' : 'd-none'
                  }
                >
                  <Col lg='3'>
                    <label
                      className={repeatingFrequency ? 'col-form-label  fw-bold fs-6' : 'd-none'}
                      htmlFor='recurOn'
                    >
                      {' '}
                      On
                    </label>
                  </Col>
                  <Col lg='9'>
                    <div className={repeatingFrequency ? 'fv-row' : 'd-none'}>
                      <div className='input-group'>
                        <select
                          {...formik.getFieldProps('recurOn')}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                          className='form-select form-control form-control-solid form-select-solid  '
                          id='recurOn'
                        >
                          <option value=''>Select Day</option>
                          {days.map((day) => (
                            <option key={day} value={day.toLowerCase()}>
                              {day}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </Col>
                </div>
              </Row>
              <Row>
                <div
                  className={
                    (repeatingMaintenance && repeatingFrequency == 'yearly') ||
                    (repeatingMaintenance && repeatingFrequency == 'monthly')
                      ? 'mb-6'
                      : 'd-none'
                  }
                >
                  <Col lg='3'>
                    <label
                      className={repeatingFrequency ? 'col-form-label fw-bold fs-6' : 'd-none'}
                      htmlFor='recurOn'
                    >
                      {' '}
                      On{' '}
                    </label>
                  </Col>
                  <Col lg='9'>
                    <div className={repeatingFrequency ? 'fv-row' : 'd-none'}>
                      <div className='input-group'>
                        <select
                          {...formik.getFieldProps('recurOn')}
                          onChange={(e) => {
                            formik.handleChange(e)
                          }}
                          className='form-select form-control form-control-solid form-select-solid'
                          id='recurOn'
                        >
                          <option value=''>Please Select..</option>
                          <option value=''>Select Date</option>
                          {dateOptions.map((date) => (
                            <option key={date} value={date}>
                              {date}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </Col>
                </div>
              </Row>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className='justify-content-end'>
          <button
            onClick={handleClose}
            type='button'
            className='btn btn-light-primary   main-btn-style me-2'
            data-bs-dismiss='modal'
          >
            Close
          </button>
          <button
            type='button'
            className='btn main-btn-style btn-primary'
            disabled={isAdding || !formik.isValid}
            onClick={handleFormSubmit}
          >
            {!isAdding && 'Submit'}
            {isAdding && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddMaintenanceModel
