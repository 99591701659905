import { decrypt } from 'config'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Images } from 'Template/assets/Assets'

interface Plan {
  name: string
  assetCount: number
  isImportExport: boolean
  isHistoryUnlimited: boolean
  isHistory: number
  priceYearly: number
  priceMonthly: number
}

interface BillingDetails {
  plan: Plan
  subscriptionType: string
  phoneNumber: string
  email: string
  firstName: string
  lastName: string
  street: string
  state: string
  apartment: string
  country: string
  floor: string
  invoiceDownloadUrl: string
}

const Invoice = () => {
  const location = useLocation()
  const { detail }: { detail: BillingDetails } = location.state || {}
  if (!detail) {
    return (
      <div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
        No Invoice Data
      </div>
    )
  }
  let decryptedDetails
  const getDecryptedetails = (details) => {
    if (!details) return {}
    return {
      apartment: decrypt(details.apartment),
      floor: decrypt(details.floor),
      state: decrypt(details.state),
      country: decrypt(details.country),
      street: decrypt(details.street),
      phoneNumber: decrypt(details.phoneNumber),
      email: decrypt(details.email),
      firstName: decrypt(details.firstName),
      lastName: decrypt(details.lastName),
    }
  }
  if (detail) {
    decryptedDetails = getDecryptedetails(detail)
  }

  const downloadInvoice = (invoiceUrl: string, plan: string) => {
    const pdfUrl = `${process.env.REACT_APP_INVOICE_URL}/${invoiceUrl}`
    const link = document.createElement('a')
    link.href = pdfUrl
    link.target = '_blank'
    link.setAttribute('download', `invoice-${plan}.pdf`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  return (
    <div className='invoice-container'>
      <div className='container'>
        <div className='billing-info'>
          <div className='card'>
            <div className='card-header border-0 bg-blue'>
              <div className='card-title m-0'>
                <img alt='Logo' src={Images.DefaultWhiteLogo} className='h-25px h-xl-30px w-100' />
              </div>
              <button
                className='download-button'
                type='button'
                onClick={() => downloadInvoice(detail?.invoiceDownloadUrl, detail?.plan?.name)}
              >
                <i className='fa-solid fa-download text-white fs-1'></i>
              </button>
            </div>
            <div className='card-footer'>
              <div className='d-flex align-items-center'>
                <p>
                  {decryptedDetails?.street} {decryptedDetails?.apartment || ''}{' '}
                  {decryptedDetails?.floor}, {decryptedDetails?.state} {decryptedDetails?.country}
                </p>
              </div>
              <div className='invoice-heading d-flex align-items-center'>
                <strong className='me-2'>Phone:</strong>
                <span>{decryptedDetails?.phoneNumber}</span>
              </div>
              <div className='invoice-heading d-flex align-items-center'>
                <strong className='me-2'>Website:</strong>
                <span>Assets Rack</span>
              </div>
              <div className='invoice-heading d-flex align-items-center'>
                <strong className='me-2'>Email:</strong>
                <span>{decryptedDetails?.email}</span>
              </div>
              <div className='invoice-heading d-flex align-items-center'>
                <strong className='me-2'>Payment Date:</strong>
                <span>{decryptedDetails?.phoneNumber}</span>
              </div>
              <div className='main-invoice'>
                <div>
                  <div className='invoice-heading d-flex align-items-center mb-3'>
                    <strong className='me-2'>Bill To:</strong>
                    <span></span>
                  </div>
                  <div className='invoice-heading d-flex align-items-center mb-3'>
                    <strong className='me-2'>Customer Name:</strong>
                    <span>
                      {decryptedDetails?.firstName} {decryptedDetails?.lastName}
                    </span>
                  </div>
                </div>
              </div>
              <div className='main-invoice'>
                <div className='invoice-heading d-flex align-items-center mb-3'>
                  <strong className='me-2'>CHARGE DETAIL:</strong>
                  <span>{detail.subscriptionType}</span>
                </div>
              </div>
              <div className='table-responsive'>
                <table className='invoice-table table-bordered mb-5'>
                  <thead>
                    <tr>
                      <th>Plan Name</th>
                      <th>Asset Counts</th>
                      <th>Import/Export</th>
                      <th>History</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{detail.plan.name}</td>
                      <td>{detail.plan.assetCount}</td>
                      <td>{detail.plan.isImportExport ? 'Supported' : 'Unsupported'}</td>
                      <td>
                        {detail.plan.isHistoryUnlimited ? 'Unlimited' : detail.plan.isHistory} days
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className='total d-flex justify-content-end align-items-center'>
                <strong>
                  Total: PKR
                  {detail?.subscriptionType === 'yearly'
                    ? detail?.plan?.priceYearly
                    : detail?.plan?.priceMonthly}
                </strong>
              </div>
              <div className='bottom-heading-invoice d-flex justify-content-center'>Thank you!</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Invoice
